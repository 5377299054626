<div class="banner">
  <span translate>header.banner</span>
</div>
<nav class="navbar">
  <div class="container-fluid">
    <div class="navbar-header">
      <a routerLink="/designs" class="navbar-brand" [class.test]="!isLive" href="#">
        <img src="/assets/img/plastar.png" class="logo">
      </a>
    </div>
    <div class="navbar-collapse collapse">
      <ul class="nav navbar-nav">
        <li routerLinkActive="active" *ngIf="isAdmin() || isManager() || isCustomer()">
          <a href="#" routerLink="/purchase-order"
             translate>header.purchaseOrders</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin() || isManager() || (isCustomer() && representsBuyer())">
          <a href="#" routerLink="/inventory"
             translate>header.inventory</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin() || isCustomer()">
          <a href="#" routerLink="/standard-products"
             translate>header.standard</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin() || isDesigner() || isManager() || (isCustomer() && representsBuyer())">
          <a href="#" routerLink="/designs"
             translate>header.designs</a>
        </li>
        <li routerLinkActive="active" *ngIf="isCustomer() && representsBuyer()">
          <a href="#" routerLink="/products" translate>header.products</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin() || isManager() || isCustomer()">
          <a href="#" routerLink="/complaints"
             translate>header.complaints</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin() || isManager()">
          <a href="#" routerLink="/users"
             translate>header.users</a>
        </li>
        <li routerLinkActive="active" *ngIf="isAdmin()">
          <a href="#" routerLink="/templates"
             translate>header.templates</a>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li>
          <language-select></language-select>
        </li>
        <li>
          <svg-icon src="/assets/icons_sprite/login.svg" class="profile-icon inline-block"></svg-icon>
        </li>
        <li class="dropdown" *ngIf="userData && userData.userId" style="padding: 10px 0;">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
             aria-haspopup="true" aria-expanded="false">
            {{userData.name}}
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" style="z-index: 10000">
            <li *ngIf="isAdmin() === true">
              <a href="#" routerLink="/app-settings" translate>header.applicationSettings</a>
            </li>
            <li *ngIf="!isLive">
              <a href="#" (click)="showTranslationCodes($event)" translate>app-settings.showTranslationCodes</a>
            </li>
            <li *ngIf="type">
              <a href="#" (click)="logout()" translate>auth.logOut</a>
            </li>
          </ul>
        </li>
        <li *ngIf="!type" style="padding: 10px 0;"><a href="#" routerLink="/login" translate>auth.logIn</a></li>
      </ul>
    </div>
  </div>
</nav>
